<!--
  * @desc:
  * @author: 肖
  * @create_date: 2021/6/9
  * @BSD
-->
<template>
  <div class="address-edit-wrap box-shadow">
    <div class="left">
      <div class="line1">{{ curAddress.pcr_address }}</div>
      <div class="line2">{{ curAddress.address }}</div>
      <div class="line3">
        <div class="text">{{ curAddress.contacts }}</div>
        <div class="text">{{ curAddress.contact_phone }}</div>
      </div>
    </div>
    <div class="right">
      <div class="btn-normal-small" @click="changeAddress">修改</div>
    </div>
  </div>
</template>

<script>
  export default {
    name:"my-address-show",
    props: {
      curAddress: [Object]
    },
    data() {
      return {

      };
    },
    methods: {
      changeAddress () {
        this.$router.push({
          name: 'addressChoose'
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .address-edit-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 32px 40px;
    height: 168px;
    box-sizing: border-box;
    margin-bottom: 40px;
    & .left {
      & .line1 {
        font-size: 24px;
        color: #8F8CA1;
        letter-spacing: 0;
        height: 24px;
        margin-bottom: 12px;
      }
      & .line2 {
        font-size: 32px;
        color: #8F8CA1;
        letter-spacing: 0;
        height: 32px;
        margin-bottom: 12px;
      }
      & .line3 {
        font-size: 24px;
        color: #686677;
        letter-spacing: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        & .text {
          margin-right: 20px;
        }
      }
    }
  }
</style>

