<!--
  * @desc:
  * @author: 肖
  * @create_date: 2021/6/9
  * @BSD
-->
<template>
  <div class="page-wrap pt188">
    <!-- 顶部 -->
    <top-bar-arrow>
      <div slot="title" class="title-image">
        商品签发
      </div>
    </top-bar-arrow>
    <div class="main">
      <div class="goodsList-list">
        <template v-for="(item, index) in goodsList">
          <div class="product-order-item-wrap" :key="index">
            <product-detail-item :item="item" :key="item.id"></product-detail-item>
          </div>
        </template>
      </div>

      <div class="address-info box-shadow" v-if="!chooseAddress">
        <div class="left">
          <div class="text">收货地址</div>
          <div class="tip">*</div>
        </div>
        <div class="right" @click="gotoAddress">
          <div class="text">请选择</div>
          <van-icon name="arrow" />
        </div>
      </div>
      <my-address-show v-else :curAddress="chooseAddress" class="my-address-show"></my-address-show>
      <div class="remarks-info box-shadow">
        <div class="title">备注</div>
        <div class="input-wrap">
          <van-field
                  v-model="formData.des"
                  rows="4"
                  autosize
                  type="textarea"
                  maxlength="300"
                  placeholder="请输入留言"
                  show-word-limit
          />
        </div>
      </div>
      <div class="btn-tj" @click="submitForm">提交</div>
    </div>
  </div>
</template>

<script>
  import topBarArrow from '../../components/top-bar-arrow'
  import productDetailItem from '../../components/product-detail-item'
  import myAddressShow from '../../components/my-address-show'
  import { mapGetters, mapMutations } from 'vuex'

  import { signOrder } from '@/common/api/shop.js'
  export default {
    name: 'sign',
    components: {
      topBarArrow,
      productDetailItem,
      myAddressShow
    },
    data() {
      return {
        formData: {
          des: ''
        },
        inputNum: 0,
        errorMsg: ''
      };
    },
    computed: {
      ...mapGetters({
        goodsList: 'getCurGoodsList',
        chooseAddress: 'getChooseAddress'
      })
    },
    mounted() {
    },
    activated(){
    },
    methods: {
      submitForm () {
        // 校验地址
        if (!this.chooseAddress) {
          this.$toast('请选择收货地址')
          return
        }
        // 校验描述长度
        if (this.inputNum > 300) {
          this.$toast('补充描述不能超过300个字符')
          return
        }
        this.signOrder()
      },
      gotoAddress () {
        this.$router.push({
          name: 'addressChoose'
        })
      },
      signOrder () {
        const box_ids = this._.map(this.goodsList, 'id')
        signOrder({
          data: {
            box_ids: box_ids.join(','),
            address_id: this.chooseAddress.id,
            des: this.formData.des
          },
          success: res => {
            if (res.code == 200) {
              this.$toast({
                type: 'success',
                message: '提交成功',
                onClose: () => {
                  this.$router.push({})
                }
              })
            } else {
              this.errorMsg = res.msg
              this.$refs.errPopup.open()
            }
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "./sign.scss"
</style>
