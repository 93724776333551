<!--
  * @desc:
  * @author: 肖
  * @create_date: 2021/6/9
  * @BSD
-->
<template>
  <div class="product-order-item box-shadow">
    <div class="left">
      <img :src="item.small_image_url" mode="" />
    </div>
    <div class="center">
      <div class="line1">{{ item.product_name }}</div>
    </div>
  </div>
</template>

<script>
  export default {
    name:"product-detail-item",
    props: {
      item: [Object]
    },
    data() {
      return {

      };
    }
  }
</script>
